$(document).on("turbolinks:load", () => {
  $(function () {
    $(".navToggle").click(function () {
      $(this).toggleClass("active");

      if ($(this).hasClass("active")) {
        $(".globalMenuSp").addClass("active");
      } else {
        $(".globalMenuSp").removeClass("active");
      }
    });
  });

  $(function () {
    $(".close_sp_header").click(function () {
      $(".navToggle").toggleClass("active");

      if ($(".navToggle").hasClass("active")) {
        $(".globalMenuSp").addClass("active");
      } else {
        $(".globalMenuSp").removeClass("active");
      }
    });
  });
  $(window).scroll(function () {
    $(".fadein").each(function () {
      var elemPos = $(this).offset().top,
        scroll = $(window).scrollTop(),
        windowHeight = $(window).height();
      if (scroll > elemPos - windowHeight + 100) {
        $(this).addClass("scrollin");
      }
    });

    $(".slideConts").each(function () {
      var elemPos = $(this).offset().top,
        scroll = $(window).scrollTop(),
        windowHeight = $(window).height();
      if (scroll > elemPos - windowHeight + 100) {
        $(this).addClass("show");
      } else {
        $(this).removeClass("show");
      }
    });
  });

  jQuery(function ($) {
    const Target = $(".is-empty");
    $(Target).on("change", function () {
      if ($(Target).val() !== "") {
        $(this).removeClass("is-empty");
      } else {
        $(this).addClass("is-empty");
      }
    });
  });

  $(function () {
    var pagetop = $(".pagetop");
    // ボタン非表示
    pagetop.hide();
    // 100px スクロールしたらボタン表示
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        pagetop.fadeIn();
      } else {
        pagetop.fadeOut();
      }
    });
    pagetop.click(function () {
      $("body, html").animate({ scrollTop: 0 }, 500);
      return false;
    });
  });

  (function (d) {
    var config = {
        kitId: "txg6quj",
        scriptTimeout: 3000,
        async: true,
      },
      h = d.documentElement,
      t = setTimeout(function () {
        h.className =
          h.className.replace(/\bwf-loading\b/g, "") + " wf-inactive";
      }, config.scriptTimeout),
      tk = d.createElement("script"),
      f = false,
      s = d.getElementsByTagName("script")[0],
      a;
    h.className += " wf-loading";
    tk.src = "https://use.typekit.net/" + config.kitId + ".js";
    tk.async = true;
    tk.onload = tk.onreadystatechange = function () {
      a = this.readyState;
      if (f || (a && a != "complete" && a != "loaded")) return;
      f = true;
      clearTimeout(t);
      try {
        Typekit.load(config);
      } catch (e) {}
    };
    s.parentNode.insertBefore(tk, s);
  })(document);

  if (window.matchMedia("(max-width: 769px)").matches) {
    $(function () {
      $(".slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        // 画像下のドット（ページ送り）を表示
        dots: true,
      });
    });

    $(function () {
      var $numberListLen = $(".column_3 .box").length;
      var defaultNum = 1;
      var addNum = 3;
      var currentNum = 0;

      $(".column_3").each(function () {
        $(this).find("#more_btn").show();
        $(this).find("#close_btn").hide();

        $(this)
          .find(".box:not(:lt(" + defaultNum + "))")
          .hide();

        currentNum = defaultNum;

        $("#more_btn").click(function () {
          currentNum += addNum;

          $(this)
            .parent()
            .find(".box:lt(" + currentNum + ")")
            .slideDown();
          if ($numberListLen <= currentNum) {
            currentNum = defaultNum;
            indexNum = currentNum - 1;

            $("#more_btn").hide();
            $("#close_btn").show();

            $("#close_btn").click(function () {
              $(this)
                .parent()
                .find(".box:gt(" + indexNum + ")")
                .slideUp();

              $(this).hide();
              $("#more_btn").show();
            });
          }
        });
      });
    });

    $(function () {
      var $numberListLen = $(".column_2 .box_01").length;
      var defaultNum = 2;
      var addNum = 2;
      var currentNum = 0;

      $(".column_2").each(function () {
        $(this).find("#more_btn01").show();
        $(this).find("#close_btn01").hide();

        $(this)
          .find(".box_01:not(:lt(" + defaultNum + "))")
          .hide();

        currentNum = defaultNum;

        $("#more_btn01").click(function () {
          currentNum += addNum;

          $(this)
            .parent()
            .find(".box_01:lt(" + currentNum + ")")
            .slideDown();
          if ($numberListLen <= currentNum) {
            currentNum = defaultNum;
            indexNum = currentNum - 1;

            $("#more_btn01").hide();
            $("#close_btn01").show();

            $("#close_btn01").click(function () {
              $(this)
                .parent()
                .find(".box_01:gt(" + indexNum + ")")
                .slideUp();

              $(this).hide();
              $("#more_btn01").show();
            });
          }
        });
      });
    });
  }
});
